// @flow

import { useEffect } from 'react';
import styled from 'styled-components';
import { Navbar, NavbarGroup, NavbarHeading } from '@blueprintjs/core';
import HeatmapViewer from '../../heatmap-viewer/index';
import Layout from '../../layouts/default-logged-in';
import { breakpoints } from '../../../styles/variables';
import { trackEvent, ENTER_PAGE } from '../../../services/mixpanel';

import * as RecordingModel from '../../../models/recording';
import * as LocationModel from '../../../models/location';
const TopBar = styled(Navbar)`
  padding-left: 2em;
  padding-right: 2em;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding: 1em;
  }
`;

type Props = {
  recordings: RecordingModel.t[],
  locations: LocationModel.t[],
  showStaffFilters: boolean,
};

const NoRecordings = () => (
  <TopBar>
    <NavbarGroup>
      <NavbarHeading>No camera placements found</NavbarHeading>
    </NavbarGroup>
  </TopBar>
);

const HeatmapsPage = (props: Props) => {
  useEffect(() => {
    trackEvent(ENTER_PAGE, 'HEATMAPS');
  }, []);

  const { recordings, locations, showStaffFilters } = props;
  return (
    <Layout>
      {recordings.length && locations.length ? (
        <HeatmapViewer
          recordings={recordings}
          locations={locations}
          showStaffFilters={showStaffFilters}
        />
      ) : (
        <NoRecordings />
      )}
    </Layout>
  );
};

export default HeatmapsPage;
