import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import React from 'react';

export const AuraSelect = ({
  activeItem,
  textSelector,
  keySelector,
  items,
  defaultText,
  handleChange,
}) => (
  <Select
    activeItem={activeItem}
    items={items}
    itemRenderer={(item, { handleClick }) => (
      <MenuItem
        key={item[keySelector]}
        text={item[textSelector]}
        onClick={handleClick}
      />
    )}
    onItemSelect={handleChange}
    filterable={false}
  >
    <Button
      text={
        activeItem
          ? activeItem[textSelector]
          : defaultText
          ? defaultText
          : 'Select an Option'
      }
      rightIcon="double-caret-vertical"
    />
  </Select>
);

const AuraSearch = ({
  activeItem,
  textSelector,
  keySelector,
  items,
  defaultText,
  handleChange,
}) => (
  <Select
    activeItem={activeItem}
    items={items}
    itemRenderer={(item, { handleClick }) => (
      <MenuItem
        key={item[keySelector]}
        text={item[textSelector]}
        onClick={handleClick}
      />
    )}
    itemPredicate={(query, item) =>
      item[textSelector].toLowerCase().includes(query.toLowerCase())
    }
    onItemSelect={handleChange}
    filterable={true}
  >
    <Button
      text={
        activeItem
          ? activeItem[textSelector]
          : defaultText
          ? defaultText
          : 'Select an Option'
      }
      rightIcon="double-caret-vertical"
      intent={Intent.PRIMARY}
    />
  </Select>
);

export default AuraSearch;
