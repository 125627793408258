export const filterTypesArray = [
  {
    key: 'lingerAccumulatedTime',
    display: 'Dwell: Total time',
  },
  {
    key: 'lingerAverageTime',
    display: 'Dwell: Average time',
  },
  { key: 'path', display: 'Popular Paths' },
];
export const filterBreakdownsArray = [
  { key: 'all', display: 'All Visitors' },
  { key: 'age', display: 'Ages' },
  { key: 'gender', display: 'Genders' },
  { key: 'role', display: 'Roles' },
];

export const ENTITY_TYPES = {
  LOCATION: 'location',
  RECORDING: 'recording',
};

export const SORTED_AGE_KEYS = [
  '0_15',
  '16_24',
  '25_34',
  '35_44',
  '45_54',
  '55_64',
  '65_100',
];