{
  "name": "aura-webclient",
  "version": "1.48.0",
  "private": true,
  "engines": {
    "node": ">=18.0",
    "npm": ">=10.0"
  },
  "overrides": {
    "nth-check": "2.0.1",
    "svgo": "^2.8.0"
  },
  "dependencies": {
    "@auravisionlabs/aura-pkg-auth-service": "1.5.11",
    "@auravisionlabs/aura-pkg-ui-formatter": "^1.4.1",
    "@auth0/auth0-spa-js": "^1.22.4",
    "@blueprintjs/core": "^3.44.3",
    "@blueprintjs/datetime": "^3.23.3",
    "@blueprintjs/popover2": "^0.9.0",
    "@blueprintjs/select": "^3.16.3",
    "@react-google-maps/api": "^1.9.7",
    "@splitsoftware/splitio": "10.18.1",
    "assert": "^2.0.0",
    "awaity": "^1.0.0",
    "buffer": "^6.0.3",
    "core-js": "^3.25.5",
    "currency-codes": "^2.1.0",
    "dexie": "^3.0.3",
    "downloadjs": "^1.4.7",
    "email-validator": "^2.0.4",
    "file-saver": "^2.0.5",
    "flow-bin": "^0.131.0",
    "flux-standard-action": "^2.0.1",
    "fs": "^0.0.1-security",
    "history": "^4.9.0",
    "https-browserify": "^1.0.0",
    "lodash": "^4.17.10",
    "logrocket": "^0.6.10",
    "mixpanel-browser": "^2.32.0",
    "moment": "^2.29.0",
    "moment-range": "^4.0.2",
    "moment-timezone": "^0.5.40",
    "os-browserify": "^0.3.0",
    "parcel": "2.3.2",
    "process": "^0.11.10",
    "query-string": "^6.1.0",
    "react": "^16.14.0",
    "react-custom-scrollbars-2": "^4.4.0",
    "react-dom": "^16.8.6",
    "react-hooks-visible": "^1.1.1",
    "react-redux": "^5.0.7",
    "react-router-dom": "^5.3.4",
    "react-transition-group": "^2.2.1",
    "recharts": "~2.1.14",
    "recharts-to-png": "~1.1.1",
    "redux": "^3.7.2",
    "redux-form": "^7.3.0",
    "redux-thunk": "~2.2.0",
    "source-map-explorer": "^1.6.0",
    "stream-http": "^3.2.0",
    "styled-components": "^5.3.6",
    "url": "^0.11.0",
    "util": "^0.11.1",
    "uuid": "^9.0.0",
    "vm-browserify": "^1.1.2",
    "whatwg-fetch": "^3.0.0"
  },
  "scripts": {
    "start": "parcel src/index.html --port 4000",
    "build": "npm dedupe && parcel build src/index.html --dist-dir build --no-cache",
    "prebuild": "rm -rf dist && rm -rf build",
    "flow": "flow",
    "storybook": "start-storybook -p 9009 -s public --ci",
    "build-storybook": "build-storybook -s public"
  },
  "browserslist": [
    "last 5 chrome versions"
  ],
  "devDependencies": {
    "@babel/core": "^7.24.4",
    "@babel/plugin-transform-flow-strip-types": "^7.24.1",
    "@parcel/packager-raw-url": "2.3.2",
    "@parcel/transformer-webmanifest": "2.3.2",
    "@parcel/watcher-linux-x64-glibc": "2.3.0",
    "babel-plugin-styled-components": "^2.1.4",
    "crypto-browserify": "^3.12.0",
    "enzyme": "^3.10.0",
    "enzyme-adapter-react-16": "^1.14.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-config-prettier": "^9.1.0",
    "events": "^3.3.0",
    "mockdate": "^3.0.5",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "path-browserify": "^1.0.1",
    "react-is": "^18.3.1",
    "stream-browserify": "^3.0.0"
  },
  "staticFiles": {
    "staticPath": "src/assets",
    "staticOutPath": "assets"
  }
}
